<template>
  <div class="viewDashBoard project-route-index">
    <div class="container">
        <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
        >
        <div key="1" v-if="loader" class="white-row loader-route">
            <div class="lds-sub-ripple">
            <div></div>
            <div></div>
            </div>
        </div>
        <div v-else class="white-row">
            <div class="show-project-portal-listing">
            <!-- Create Project Form  -->
            <div class="form-area">
                <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12">
                    <div class="row top-gen-heading">
                        <h1 class="global-style-heading">
                        Lead Importer
                        </h1>
                    </div>
                    </div>
                    <div class="col-sm-12">
                    <form
                        class="brand-form"
                        v-on:submit.prevent="onSubmit($event)"
                        enctype="multipart/form-data"
                    >
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row">
                                <div class="form-group">
                                    <div class="custom-file">
                                        <input@change="onFileChange" type="file" class="custom-file-input">
                                        <label class="custom-file-label" for="customFile">{{ file_name }}</label>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="row">
                                <div class="form-actions">
                                    <button
                                    :disabled="disableSubmitBtn"
                                    type="submit"
                                    id="save-form"
                                    class="btn btn-primary default-global-btn"
                                    >
                                    Upload
                                    </button>
                                    <div
                                    v-if="disableSubmitBtn"
                                    class="form_submit_loader"
                                    >
                                    <div class="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </transition>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {
      loader: true,
      file_name : 'Chose File',
      formData: {
        file: null,
      },
      disableSubmitBtn: false
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.loader = false;
  },
  methods: {
      onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
          this.file_name = '';
          this.formData.file = null;
      } else {
          this.file_name = files[0].name;
          this.formData.file = files[0];
      }
    //     return;
    //   this.createImage(files[0]);
    },
    async onSubmit(event) {
        const $this = this;
        //   $this.disableSubmitBtn = true;
        event.preventDefault();
        let currentObj = this;

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        let formData = new FormData();
        formData.append('file', this.formData.file);

        window.axios.post('/leads/lead-importer', formData, config)
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error.response);
        });
    
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>
<style lang="scss" scoped>
body .form-area .form-group label span {
  color: red;
}
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.project-route-index {
  .show-project-portal-listing {
    .general-filters {
      .form-group {
        width: 100%;
      }
      .form-actions {
        width: 100%;
        button {
          width: 100%;
          margin-left: 0;
          padding: 5px 0;
        }
      }
      span.select2.select2-container.select2-container--default {
        width: 100% !important;
      }
    }
    h4 {
      letter-spacing: 0.28px;
      color: #323f54;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 7px;
    }
    p {
      margin: 0;
      letter-spacing: 0.28px;
      color: #2a1c0b;
    }
    section {
      border-bottom: 1px solid #ebebeb;
      padding: 30px 0;
      &.client-projects {
        padding-bottom: 0px;
        h4 {
          padding: 5px 0 25px;
        }
        .wrap-box {
          background: #faf9f7 0% 0% no-repeat padding-box;
          border-radius: 3px;
          padding: 20px 15px;
          min-height: 231px;
          margin-bottom: 30px;
          .box {
            position: relative;
            .project-details {
              p {
                &.project-id {
                  background: #ece9df 0% 0% no-repeat padding-box;
                  border-radius: 3px;
                  display: inline-block;
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 4px 10px;
                }
                &.project-status {
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 3px 0 15px;
                  font-weight: 400;
                }
              }
              .project-listing {
                padding: 15px 0 15px;
                p {
                  margin: 0;
                  letter-spacing: 0.28px;
                  color: #323f54;
                  font-size: 14px;
                  font-weight: bold;
                  text-transform: inherit;
                  line-height: 25px;
                }
              }
            }
            .footer-box {
              display: block;
              position: relative;
              width: 100%;
              padding: 20px 0 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              .usr-role {
                p {
                  letter-spacing: 0.24px;
                  color: #918a82;
                  font-weight: 300;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>